import React from 'react';
import {useTranslation} from 'react-i18next';

const data = {
  show: true,
  header: ["Important Announcements", ""],
  headerEs: ["Anuncios importantes", ""],
  message: [
    "Due to enhanced security, users must update their password with at least a 12-character limit. Visit the Help Center for details.",
    "",
    "We have also received reports from individuals about the sudden slowness of games being played, game progress not saving, and the inability to issue certificates. We are investigating the issue.",
  ],
  messageEs: [
    "Debido a las mejoras en la seguridad, los usuarios deben actualizar su contraseña con un mínimo de 12 caracteres. Visite el Centro de Ayuda para detalles.",
    "",
    "También hemos recibido informes de personas sobre la lentitud repentina de los juegos que se juegan, el progreso del juego que no se guarda y la incapacidad de emitir certificados. Estamos investigando el problema.",
  ],
  level: "info",
  levelOptions: "info, success, warning, error, emergency",
};

const para = (text = '', bold = false, i = 0) => {
  if (text === '') return <div key={i} className='padding-top-1'></div>;
  return (
    <p key={i} className={`margin-0${bold ? ' text-bold' : ''}`}>
      {text}
    </p>
  );
};

const handleText = (text, isHeader = false) => {
  if (Array.isArray(text)) return text.map((t, i) => para(t, isHeader, i));
  else return para(text, isHeader);
};

const displayAlert = (header = null, message = 'message', type = 'info') => (
  <div className={`usa-alert usa-alert--${type}`}>
    <div className='usa-alert__body'>
      <div className='usa-alert__text padding-right-6'>
        {header !== null && handleText(header, true)}
        {handleText(message)}
      </div>
    </div>
  </div>
);

const TempMessage = () => {
  const {show, header, headerEs, message, messageEs, level} = data;
  const {i18n} = useTranslation();

  if (!show) return null;
  const isSpanish = i18n.language === 'es';
  const h = isSpanish ? headerEs : header;
  const m = isSpanish ? messageEs : message;
  return displayAlert(h, m, level);
};

export default TempMessage;
