import React, {useState} from 'react';
import {Formik} from 'formik';
import {observer} from 'mobx-react';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';

import RequestService from '../../services/RequestService';
import config from '../../config';
import FormInput from '../../components/Formik/FormInput';

const {passwordSchema, passwordRequirements} = config;

const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      passwordSchema,
      passwordRequirements
    )
    .required("Password is required."),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match.")
    .required("You must confirm your password."),
});

const changePassword = (values, handleErrorResponse, history) =>
  RequestService.post(
    `${config.apiGateway.URL}/update-password`,
    {user: {password: values.password}},
    (res) => {
      history.push('/account');
    },
    (error) => {
      handleErrorResponse(error.response);
    },
  );

const ChangePasswordForm = observer(({formikProps, hasError, errorMessage}) => {
  const {t} = useTranslation();
  const changePwdText = t('Change Password');
  const cancelText = t('Cancel');

  return (
    <div>
      <form className='reg-form padding-top-2 padding-bottom-4'>
        <fieldset>
          <h1>{changePwdText}</h1>
          {hasError && (
            <div className='usa-alert usa-alert--warning margin-bottom-3'>
              <div className='usa-alert__body'>
                <p className='usa-alert__text'>{errorMessage}</p>
              </div>
            </div>
          )}
          <div className='container text-align-center'>
            <div className='grid-row margin-bottom-2'>
              <div className='grid-col-6 text-align-left padding-right-2 mobile-full-width'>
                <FormInput formikProps={formikProps} type='password' id='password' label='New Password' />
              </div>
              <div className='grid-col-6 text-align-left mobile-full-width'>
                <FormInput formikProps={formikProps} type='password' id='passwordConfirmation' label='New Password' />
              </div>
            </div>
            <div className='reg-form-btn'>
              <button
                id='change-password'
                onClick={(e) => e.preventDefault() || formikProps.handleSubmit()}
                className='usa-button fdic-button tan-button square-button'
              >
                {changePwdText}
              </button>
            </div>
            <div className='margin-top-2'>
              <Link to='/account'>{cancelText}</Link>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
});

const ChangePassword = observer((props) => {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleErrorResponse = (response) => {
    setErrorMessage(response.data.message);
    setError(true);
  };
  return (
    <div className='registration-wrapper'>
      <Formik
        initialValues={{password: '', passwordConfirmation: ''}}
        validationSchema={changePasswordSchema}
        onSubmit={(values) => changePassword(values, handleErrorResponse, history)}
      >
        {(formikProps) => (
          <ChangePasswordForm formikProps={formikProps} hasError={error} errorMessage={errorMessage} {...props} />
        )}
      </Formik>
    </div>
  );
});

export default ChangePassword;
export {changePasswordSchema};
