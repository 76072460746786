import React, {useState} from 'react';
import {Formik, Field} from 'formik';
import {Link, Redirect} from 'react-router-dom';
import * as Yup from 'yup';
import {Typeahead} from 'react-bootstrap-typeahead';
import {observer} from 'mobx-react';
import Filter from 'bad-words';
import {useTranslation} from 'react-i18next';

import {getOrganizationFromOptions} from '../../services/OrganizationUtilities';
import RequestService from '../../services/RequestService';
import config from '../../config';
import FormInput, {hasAlert} from '../../components/Formik/FormInput';
import LanguageSelector from '../../components/Buttons/LanguageSelector';
import MandatoryDisclosure from '../MandatoryDisclosure';

const filter = new Filter();
// whitelist for filter
filter.removeWords('cox', 'knob');
const registerSuccessString = '?register=success';

const {passwordRequirements} = config;

const nameRequirements =
  'The name you provide here will appear on all of your certificates for games you win while logged in with this account.';

const organizationDisclosure =
  'If you link your account to an organization’s account, that organization can view your Player ID, first and last name, email address, your progress playing games, and your earned certificates.';

const tempMessage =
  'The FDIC is currently surveying users of How Money Smart Are You? so that we can get feedback to improve this website. Please look for emails inviting you to participate in these surveys.';

function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: (value) => {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const registrationSchema = Yup.object().shape({
  createAccountEmail: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .email('Must enter a valid email address.')
    .min(2, 'Email must be at least two characters.')
    .max(50, 'Email must be at most 50 characters.')
    .required('Email is required.'),
  password: Yup.string()
    .matches(config.passwordSchema, passwordRequirements)
    .required('Password is required.'),
  firstName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'First name must be at least two characters.')
    .max(50, 'First name must be at most 50 characters.')
    .required('First name is required.'),
  lastName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Last name must be at least two characters.')
    .max(50, 'Last name must be at most 50 characters.')
    .required('Last name is required.'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('You must confirm your password.'),
  emailConfirmation: Yup.string()
    .oneOf([Yup.ref('createAccountEmail'), null], 'Emails must match.')
    .required('You must confirm your email.'),
  organizationId: Yup.number().when('accountType', (accountType, schema) => {
    return accountType === 'organization'
      ? schema.required('You must enter an existing Organization Display Name.')
      : schema;
  }),
});

const register = (values, authStore, history) => {
  let body = {
    user: {
      email: values.createAccountEmail,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName,
      account_type: 'individual',
      organization_id: values.organizationId,
    },
  };
  RequestService.post(
    `${config.apiGateway.URL}/signup`,
    body,
    (response) => {
      // console.log(response.data);
      history.push(`/login${registerSuccessString}`);
    },
    (error) => {
      if (error.response.status === 400) {
        authStore.registrationEmailFailure = true;
      }
    },
  );
};

const getOrganizationOptions = (organizationStore) =>
  organizationStore.organizations
    .filter((organization) => organization.approved && !organization.suspended)
    .map((organization) => ({
      id: organization.id,
      label: organization.display_name,
    }));

const Register = observer((props) => {
  const [showMandatoryStatements, setShowMandatoryStatements] = useState(false);

  config.setUniqueTitle('Create an Account');

  const {authStore, organizationStore, skipValidation} = props;
  organizationStore.getOrganizations();
  const organizationOptions = getOrganizationOptions(organizationStore);
  let emailTaken = authStore.registrationEmailFailure;
  const {t} = useTranslation();

  if (authStore.loggedIn) {
    return <Redirect to='/account' />;
  }

  return (
    <div className='registration-wrapper margin-left-auto margin-right-auto'>
      <Formik
        validationSchema={skipValidation ? null : registrationSchema}
        initialValues={{
          createAccountEmail: '',
          emailConfirmation: '',
          password: '',
          passwordConfirmation: '',
          firstName: '',
          lastName: '',
          zipCode: '',
          accountType: 'individual',
        }}
        onSubmit={(values) => register(values, props.authStore, props.history)}
      >
        {(formikProps) => (
          <form className='reg-form'>
            <fieldset>
              <div className='grid-row text-align-center margin-top-0'>
                <h1 className='grid-col-12 margin-bottom-105 margin-top-1'>{t('Create an Account')}</h1>
                <div className='grid-col text-center'>
                  <LanguageSelector {...props} />
                </div>
                <div className='padding-left-2 padding-right-2 text-center'>
                  <p>
                    <b>
                      {t('This is for Individual Player accounts only.')}
                      <br />
                      {t('Organizations should NOT create an account here.')}
                    </b>
                    <br />
                    <Link to='/help-center/18'>{t('Request an Organization account')}</Link>
                    <span>.</span>
                  </p>
                  <p>
                    {t(
                      'To play the Games, you need an account only if you want to earn certificates or have your progress saved. To use the Resources, you don’t need an account. Anything you enter into the Resources is not saved to an account.',
                    )}
                  </p>
                  <p>
                    <b>{t('**Be sure to enter a VALID email address.**')}</b>
                  </p>
                  {/* <p>If you forget your password, we will send a new password to this address.</p> */}
                  <p className='margin-bottom-0'>{t('All fields marked with * are required.')}</p>
                </div>
              </div>
              <div className='container padding-left-4'>
                <div className='grid-row margin-bottom-2 flex-align-end'>
                  <div className='grid-col-6 padding-right-2 mobile-full-width'>
                    <FormInput
                      formikProps={formikProps}
                      type='email'
                      id='createAccountEmail'
                      label={'Email'}
                      required
                    />
                  </div>
                  <div className='grid-col-6 padding-right-2 mobile-full-width'>
                    <FormInput
                      formikProps={formikProps}
                      type='email'
                      id='emailConfirmation'
                      label={'Re-enter email:'}
                      required
                    />
                  </div>
                </div>
                <div className='grid-row margin-bottom-2'>
                  <div className='grid-col-6 padding-right-2 mobile-full-width'>
                    <FormInput
                      formikProps={formikProps}
                      type='password'
                      id='password'
                      label={'Password'}
                      showTooltip
                      required
                    />
                  </div>
                  <div>
                    <FormInput
                      formikProps={formikProps}
                      type='password'
                      id='passwordConfirmation'
                      label={'Re-enter password:'}
                      required
                    />
                  </div>
                </div>
                <div className='grid-row margin-bottom-2'>
                  <div className='grid-col-6 padding-right-2 mobile-full-width'>
                    <FormInput
                      formikProps={formikProps}
                      type='text'
                      id='firstName'
                      label={'First Name:'}
                      showTooltip
                      required
                    />
                  </div>
                  <div>
                    <FormInput
                      formikProps={formikProps}
                      type='text'
                      id='lastName'
                      label={'Last Name:'}
                      showTooltip
                      required
                    />
                  </div>
                </div>
                <div className='grid-row margin-bottom-2'>
                  <label htmlFor='accountType' className='margin-bottom-2'>
                    {t(
                      'Will you be playing these games on your own or through an organization (such as a bank, community organization, or school)?',
                    )}
                  </label>
                  <div className='display-flex flex-align-center usa-radio'>
                    <Field
                      id='individual'
                      type='radio'
                      name='accountType'
                      value='individual'
                      label='On your own'
                      className='usa-radio__input'
                    />
                    <label htmlFor='individual' className='margin-right-2 usa-radio__label'>
                      {t('On my own')}
                    </label>
                  </div>
                  <div className='display-flex flex-align-center usa-radio'>
                    <Field
                      id='organization'
                      type='radio'
                      name='accountType'
                      value='organization'
                      label='Through an organization'
                      className='usa-radio__input'
                    />
                    <label htmlFor='organization' className='usa-radio__label'>
                      {t('Through an organization')}
                    </label>
                  </div>
                </div>

                {formikProps.values.accountType === 'organization' && (
                  <div className='grid-row margin-bottom-2'>
                    <div className='grid-col-12 margin-bottom-2'>
                      <i>{t(organizationDisclosure)}</i>
                    </div>

                    <div className='grid-col-12 padding-right-2 mobile-full-width'>
                      <div className='text-center margin-bottom-3 grid-col-10 grid-offset-1'>
                        <i>
                          <b>
                            {t(
                              '**The organization will not have access to any information about your use of the Resources.**',
                            )}
                          </b>
                        </i>
                      </div>
                      <label htmlFor='organizationName' className='text-no-wrap'>
                        {t('Organization Display Name (given to you by the organization):')} *
                      </label>
                      <Typeahead
                        id='organizationPicker'
                        selected={
                          formikProps.values.organizationId &&
                          getOrganizationFromOptions(organizationOptions, formikProps.values.organizationId)
                        }
                        multiple={false}
                        minLength={3}
                        options={organizationOptions}
                        onChange={(values) => values[0] && formikProps.setFieldValue('organizationId', values[0].id)}
                        onBlur={() => formikProps.setFieldTouched('organizationId')}
                        emptyLabel={`${t('No matches found')}.`}
                        filterBy={(option, props) => option.label.toLowerCase().indexOf(props.text.toLowerCase()) === 0}
                      />
                      {hasAlert(formikProps, 'organizationId') && (
                        <div className='margin-top-1 alert-registration'>{t(formikProps.errors['organizationId'])}</div>
                      )}
                    </div>
                  </div>
                )}
                {emailTaken && (
                  <div className='margin-top-1 margin-bottom-1 alert alert-box'>
                    <p>
                      {t('This email is already associated with an account')}.
                      <Link to='/password-reset'>{t('Forgot password?')}</Link>
                    </p>
                  </div>
                )}
                <div className='reg-form-btn text-align-center margin-bottom-3 padding-right-2'>
                  <p className='margin-bottom-0 text-italic'>{tempMessage}</p>
                  <p>{t('By creating an account, you are verifying you are age 13 or older.')}</p>
                  <button
                    id='register'
                    className='usa-button fdic-button tan-button square-button padding-left-2'
                    onClick={() => setShowMandatoryStatements(true)}
                    type='button'
                    title={t('Create an Account')}
                    disabled={!formikProps.isDirty && (!formikProps.isValid || formikProps.isSubmitting)}
                  >
                    {t('Create an Account')}
                  </button>
                  <Link className='link-btn' to='/login'>
                    {t('Already Have an Account?')}
                  </Link>
                </div>
              </div>
              <MandatoryDisclosure
                {...props}
                submit={() => formikProps.handleSubmit()}
                show={showMandatoryStatements}
                setShow={setShowMandatoryStatements}
              />
            </fieldset>
          </form>
        )}
      </Formik>
    </div>
  );
});

export {registrationSchema, passwordRequirements, organizationDisclosure, registerSuccessString, nameRequirements};
export default Register;
