const config = {
  apiGateway: {
    // npm build sets NODE_ENV = 'production'
    // npm start sets NODE_ENV = 'development'
    // So when run locally it will work with the API container on 3001
    // and when built by pipeline it will work w/ the relative URL of /api and work in any landing zone.
    URL:
      process.env.NODE_ENV === "production"
        ? "/api"
        : "http://localhost:3001/api",
  },
  cognito: {
    REGION: "AWS_REGION",
    USER_POOL_ID: "COGNITO_USER_POOL_ID",
    APP_CLIENT_ID: "COGNITO_APP_CLIENT_ID",
  },
  setUniqueTitle: (uniqueTitle) =>
    (document.title = `FDIC's How Money Smart Are You? - ${uniqueTitle}`),
  defaultQueryStartDate: oneMonthAgo(),
  /* eslint-disable */
  passwordSchema:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.{12,})/,
  /* eslint-enable */
  passwordRequirements:
    "Password must be at least 12 characters, have one lowercase letter, one uppercase letter, one special character, and one number.",
  registerSuccessString: "?register=success",
};

export default config;

function oneMonthAgo() {
  // local time
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const lastMonthDate = date.toLocaleDateString("fr-CA");
  return lastMonthDate;
}
